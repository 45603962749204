import Modal from 'react-modal'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle, keyframes } from 'styled-components'
import { transparentize } from 'polished'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useRef, useEffect } from 'react'
import { down } from 'styled-breakpoints'
import Title from './Title'
import IconClose from '../assets/icons/close.svg?sprite'

Modal.setAppElement('#__next')

function CustomModal({ children, title, maxWidth, isOpen, onChange }) {
  const overlayRef = useRef()
  const contentRef = useRef()

  function closeModal() {
    onChange(false)
  }

  function handleAfterOpen() {
    disableBodyScroll(overlayRef.current)
  }

  function handleAfterClose() {
    clearAllBodyScrollLocks()
  }

  function handleRequestClose() {
    closeModal()
  }

  function overlayRefCb(node) {
    overlayRef.current = node
  }

  function contentRefCb(node) {
    contentRef.current = node
  }

  function handleClickClose(e) {
    e.preventDefault()

    closeModal()
  }

  useEffect(() => {
    return () => clearAllBodyScrollLocks()
  }, [])

  return (
    <>
      <GlobalStyle />
      <Modal
        className="ws-custom-modal"
        overlayClassName="ws-custom-overlay"
        onAfterOpen={handleAfterOpen}
        onAfterClose={handleAfterClose}
        onRequestClose={handleRequestClose}
        closeTimeoutMS={0}
        contentLabel={title}
        overlayRef={overlayRefCb}
        contentRef={contentRefCb}
        isOpen={isOpen}
      >
        <StyledCustomModal>
          <StyledCustomModalInner maxWidth={maxWidth}>
            <StyledCustomModalClose href="#" onClick={handleClickClose}>
              <IconClose width={16} height={16} />
            </StyledCustomModalClose>
            <StyledCustomModalTitle>
              <Title>{title}</Title>
            </StyledCustomModalTitle>
            <StyledCustomModalBody>{children}</StyledCustomModalBody>
          </StyledCustomModalInner>
        </StyledCustomModal>
      </Modal>
    </>
  )
}

CustomModal.defaultProps = {
  title: 'Заголовок',
  maxWidth: '1024px',
  isOpen: false,
  onChange: () => {},
}

CustomModal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  isOpen: PropTypes.bool,
  onChange: PropTypes.func,
}

export default CustomModal

const zoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`

const GlobalStyle = createGlobalStyle`
  .ws-custom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${(props) => props.theme.zIndexModal};
    overflow: hidden auto;
    overscroll-behavior: contain;
    cursor: pointer;
    background-color: ${(props) => transparentize(0.5, props.theme.black)};
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);

    ${down('lg')} {
      background-color: transparent;
    }
  }
  .ws-custom-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    pointer-events: none;
  }
`

const StyledCustomModal = styled.div`
  position: relative;
  padding: 48px;
  pointer-events: none;

  ${down('lg')} {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    flex-grow: 1;
  }
`

const StyledCustomModalInner = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  max-width: ${(props) => props.maxWidth};
  padding: 40px;
  border-radius: ${(props) => props.theme.borderRadius};
  background-color: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.boxShadow};
  cursor: auto;
  pointer-events: all;
  animation: ${zoomIn} ${(props) => props.theme.transitionTime};

  ${down('lg')} {
    background-color: ${(props) => props.theme.bodyBg};
    max-width: none;
    flex-grow: 1;
    box-shadow: none;
    border-radius: 0;
  }

  ${down('md')} {
    padding: 20px;
  }
`

const StyledCustomModalTitle = styled.div`
  margin-bottom: 24px;
  padding-right: 30px;

  ${down('lg')} {
    padding-right: 60px;
  }
`

const StyledCustomModalClose = styled.a`
  position: absolute;
  right: 24px;
  top: 34px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  line-height: 0;
  text-decoration: none;
  color: ${(props) => props.theme.colorSecondary};
  transition: opacity ${(props) => props.theme.transitionTime};
  z-index: 1;

  ${down('lg')} {
    position: sticky;
    margin-bottom: -35px;
    margin-left: auto;
    background-color: hsla(0, 0%, 100%, 0.7);
    border-radius: 5px;
  }

  ${down('md')} {
    top: 15px;
    right: 10px;
  }

  &:hover {
    opacity: ${(props) => props.theme.opacity};
  }
`

const StyledCustomModalBody = styled.div``
